import React from 'react';
import * as Styled from './imageWithTextLinkSVGRightStyles';
import { BLOCKS, INLINES } from '@contentful/rich-text-types';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import useContentfulAssets from '../../hooks/useContentfulAssets';
import { Body } from '../../styles';
import isExternalUrl, { addTrailingSlash, isExternalUrlHref } from '../../utils';

const ImageWithTextLinkSVGRight = ({ sectionData }) => {
  const optionsMainStyle = {
    renderNode: {
      [BLOCKS.HEADING_2]: (node, children) => (
        <Styled.HeaderH2>{children}</Styled.HeaderH2>
      ),
      [BLOCKS.HEADING_3]: (node, children) => (
        <Styled.TitleStyle>{children}</Styled.TitleStyle>
      ),
      [BLOCKS.HEADING_4]: (node, children) => (
        <Styled.HeadingH4>{children}</Styled.HeadingH4>
      ),
      [BLOCKS.PARAGRAPH]: (node, children) => (
        <Styled.ParaStyle>{children}</Styled.ParaStyle>
      ),
      [INLINES.HYPERLINK]: (node, children) => (
        <Styled.ButtonStyle
          href={
            isExternalUrlHref(node?.data?.uri)
              ? node?.data?.uri
              : addTrailingSlash(process.env.GATSBY_DOMAIN_URL + node?.data?.uri)
          }
          target={isExternalUrl(node?.data?.uri) ? '_blank' : '_self'}
        >
          {children}
        </Styled.ButtonStyle>
      ),
      [BLOCKS.EMBEDDED_ASSET]: (node) => {
        const asset = useContentfulAssets(node?.data?.target?.sys?.id);
        return asset?.node?.file?.url?.includes('image') ? (
          <Styled.ImageStyle
            src={asset?.node?.file?.url}
            alt={asset?.node?.title}
          />
        ) : (
          <></>
        );
      },
    },
    renderText: (text) =>
      text.split('\n').flatMap((text, i) => [i > 0 && <br key={i} />, text]),
  };
  return (
    <Styled.Container>
      <Body>
        {sectionData?.header?.trim() && (
          <Styled.HeaderH2>{sectionData?.header}</Styled.HeaderH2>
        )}
        {sectionData?.subText?.trim() && (
          <Styled.TitleStyle>{sectionData?.subText}</Styled.TitleStyle>
        )}
        {/* {sectionData?.title && (
          <Styled.TitleStyle>{sectionData.title}</Styled.TitleStyle>
        )} */}
        {sectionData?.image?.file && (
          <a
            href={
              isExternalUrlHref(sectionData?.path)
                ? sectionData?.path
                : addTrailingSlash(process.env.GATSBY_DOMAIN_URL + sectionData?.path)
            }
            target={isExternalUrl(sectionData?.path) ? '_blank' : '_self'}
            rel="noreferrer"
          >
            <Styled.ImageStyle
              src={sectionData?.image?.file?.url}
            ></Styled.ImageStyle>
          </a>
        )}
        {sectionData?.contentDetails &&
          documentToReactComponents(
            JSON.parse(sectionData?.contentDetails?.raw),
            optionsMainStyle
          )}
        {sectionData?.richText &&
          documentToReactComponents(
            JSON.parse(sectionData?.richText?.raw),
            optionsMainStyle
          )}
      </Body>
    </Styled.Container>
  );
};
export default ImageWithTextLinkSVGRight;
